import React, { PureComponent, Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.scss";

const Home      = lazy(() => import("./pages/Home/Home"));
const NotFound  = lazy(() => import("./pages/NotFound/NotFound"));

export default class App extends PureComponent {
  render() {
    return (
      <Suspense>
        <Routes>
          <Route index path="/" element={<Home />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    );
  }
}
